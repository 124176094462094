import OrderIndex from '@/views/Order/OrderIndex'

import ScanIndex from "@/views/Scan/ScanIndex.vue"
import SettingsIndex from '@/views/Settings/SettingsIndex'
import OrderCategories from "@/views/Order/OrderCategories"
import OrderCategory from "@/views/Order/OrderCategory"

const routes = [
    {
        path: '/',
        name: 'order.index',
        component: OrderIndex,
    },
    {
        path: '/scan',
        name: 'scan.index',
        component: ScanIndex,
    },
    {
        path: '/settings',
        name: 'settings.index',
        component: SettingsIndex,
    },
    {
        path: '/order/:id/categories',
        name: 'order.categories',
        component: OrderCategories,
    },
    {
        path: '/order/:id/categories/:category',
        name: 'order.category',
        component: OrderCategory,
    }
];

export default routes;