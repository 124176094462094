<template>
    <div class="app-modal-overlay" v-if="visible">
        <div class="app-modal app-modal--large">
            <div class="app-modal-header d-flex justify-content-between">
                Print Tray Barcodes
                <span @click="hide()" role="button"><i class="fa-solid fa-xmark pt-1 pe-1 ps-3 pb-3"></i></span>
            </div>
            <div class="app-modal-body">

                <span class="fs-15">
                    Tray: <strong>{{ code }}</strong> <div class="d-flex ms-4" v-if="fetching"><span class="loader"></span></div>
                </span>

                <template v-if="tray">
                    <div v-for="order in tray.orders" :key="order.id" class="mt-3 bg-light p-3">
                        <span class="d-block fs-125">Order: <strong>{{ order.external_reference_1 }}</strong></span>
                        <span class="d-block fs-125 mt-1">Customer: <strong>{{ order.customer }}</strong></span>
                        <span class="d-block fs-125 mt-1" v-if="order.deadline_at">Deadline: <strong>{{ order.deadline_at }}</strong></span>
                        <strong class="d-block fs-15 mt-3 mb-2" v-if="order.remark"><i class="fa-solid fa-triangle-exclamation d-inline-block me-2 text-warning"></i> Remark!</strong>
                        <strong class="d-block fs-125 bg-warning bg-opacity-50 p-3 fs-125" v-if="order.remark">{{ order.remark }}</strong>
                    </div>



                </template>

                <div class="mt-4">
                    <span class="form-check-label" style="font-size: 1.5rem;">
                        <span class="d-inline-block me-2">Print barcodes</span>
                       <i class="fa-solid fa-circle-check text-success" v-if="options.product_labels"></i>
                        <i class="fa-solid fa-circle-xmark text-secondary" v-if="!options.product_labels"></i>
                    </span>
                </div>

                <div class="bg-danger bg-opacity-10 p-3 border border-2 border-danger rounded text-danger mt-3" v-if="error">
                    <vue-json-pretty :data="errorDebug" />
                </div>

            </div>
            <div class="app-modal-footer d-flex align-items-center justify-content-start">
                <div></div>
                <button class="btn btn-primary px-4 py-2" style="font-size: 1.5rem;" v-if="tray" @click="process" :disabled="processing || completed || error">
                    Print
                </button>
                <div class="d-flex ms-4" v-if="printing"><span class="loader"></span></div>
                <div v-if="printed" class="d-flex align-items-center text-success ms-4">
                    <span class="fs-2"><i class="fa-regular fa-circle-check"></i></span>
                    <span class="fs-15 d-inline-block ms-3">Completed</span>
                </div>
                <div v-if="error" class="d-flex align-items-center text-danger ms-4">
                    <span class="fs-2"><i class="fa-regular fa-circle-xmark"></i></span>
                    <span class="fs-15 d-inline-block ms-3">Error</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import PrintService from "@/services/PrintService";

export default {

    components: {
        VueJsonPretty,
    },

    props: {
        visible: Boolean,
        code: String,
    },

    setup() {
        const service = new PrintService();

        return {
            service,
        }
    },

    data() {
        return {
            fetching: false,
            fetched: false,

            printing: false,
            printed: false,

            tray: null,

            options: {
                product_labels: false,
            },

            error: false,
            errorMessage: '',
            errorDebug: null,
        }
    },

    methods: {
        process() {
            if (this.processing) {
                return;
            }

            this.printing = true;
            this.printed = false;
            this.error = false;

            this.service.printTrayBarcodes(this.code, this.options).then(() => {
                this.printed = true;
                setTimeout(() => this.hide(), 500);
            }).catch((error) => {
                this.error = true;
                this.errorDebug = JSON.parse(JSON.stringify(error));
                alert("Something went wrong! Please take a picture of the error and send it to the software developer.")
            }).finally(() => {
                this.printing = false;
            })
        },
        hide() {
            this.$emit('hide')
        },
        onFetch() {
            for (const order of Object.values(this.tray.orders)) {
                if ((order.remark ?? '').includes('WITH barcodes')) {
                    console.log('has lables');
                    this.options.product_labels = true;
                } else {
                    this.options.product_labels = false;
                }
            }
        }
    },

    watch: {
        visible() {
            this.printing = false;
            this.printed = false;
            this.fetching = true;
            this.fetched = false;
            this.error = false;

            this.service.get(this.code).then((response) => {
                this.tray = response.data;
                this.fetched = true;
                this.onFetch();
            }).catch((error) => {
                this.error = true;
                this.errorDebug = JSON.parse(JSON.stringify(error));
                alert("Something went wrong! Please take a picture of the error and send it to the software developer.")
            }).finally(() => {
                this.fetching = false;
            })
        }
    },
}
</script>