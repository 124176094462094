<template>
    <div class="d-flex flex-row justify-content-between align-items-center py-3 bg-light ps-3 pe-4 mb-3">
        <img src="/images/logo.png" style="max-height: 40px;" />
        <div @click="modals.menu.visible = true">
            <i class="fa-solid fa-bars ms-2 fs-2" role="button"></i>
        </div>
    </div>

    <div class="row mb-3">
        <div class="col-12">
            <div class="d-flex justify-content-between align-items-center px-3 pt-3">
                <h2 class="fw-light m-0">Orders</h2>

                <div class="d-flex">
                    <button class="btn ms-3" style="font-size: 1.5rem;" @click="debtor = 'pyramid'" :class="{
                        'btn-light': debtor !== 'pyramid',
                        'btn-primary': debtor === 'pyramid',
                    }">Pyramid</button>
                    <button class="btn ms-3" style="font-size: 1.5rem;" @click="debtor = 'other'" :class="{
                        'btn-light': debtor !== 'other',
                        'btn-primary': debtor === 'other',
                    }">Other</button>
                </div>
            </div>
        </div>
    </div>
    <div class="row pb-5">
        <div class="col-12">

            <div class="px-3">
                <div class="table-head d-flex justify-content-between">
                    <div class="d-flex flex-grow-1">
                        <strong class="d-inline-block" :style="{'min-width' : style.col_1_width + 'px'}">Order</strong>
                        <strong class="d-inline-block flex-grow-1" >Customer</strong>
                        <strong class="d-inline-block text-center"
                                :style="{'width' : style.col_2_width + 'px'}">Deadline</strong>
                    </div>
                    <div class="d-flex">
                        <strong class="d-inline-block text-center"
                                :style="{'width' : style.col_3_width + 'px'}">Components</strong>
                        <strong class="d-inline-block text-center"
                                :style="{'width' : style.col_6_width + 'px'}">Mugs</strong>
                        <strong class="d-inline-block text-center"
                                :style="{'width' : style.col_7_width + 'px'}">Files</strong>
                        <strong class="d-inline-block text-end pe-3"
                                :style="{'width' : style.col_5_width + 'px'}">Status</strong>
                    </div>
                </div>
                <div class="table-body">
                    <div v-for="order in ordersFiltered" :key="order.id">
                        <router-link :to="{ name: 'order.categories', params: { id: order.id }}"
                                     class="text-decoration-none text-body">
                            <div class="d-flex justify-content-between" style="height: 5.25rem">
                                <div class="d-flex flex-grow-1 ps-3">
                                    <div :style="{'min-width' : style.col_1_width + 'px'}" class="d-flex justify-content-start align-items-center">
                                        <div class="d-flex flex-column justify-content-center">
                                            <span class="fw-bold ">{{ order.external_reference_1 }}</span>
                                        </div>
                                        <i v-if="order.remark" class="fa-solid fa-triangle-exclamation ms-2" style="color: #f39f00; font-size: 1.25rem;"></i>
                                    </div>
                                    <div :style="{'width' : style.col_11_width + 'px'}"
                                         class="d-flex justify-content-start align-items-center flex-grow-1 pe-1">
                                    <span class="fw-bold" style="font-size: 1rem !important;">{{
                                            order.customer
                                        }}</span>
                                    </div>
                                    <div :style="{'width' : style.col_2_width + 'px'}"
                                         class="d-flex justify-content-center align-items-center">
                                        <strong>{{ order.deadline_at }}</strong>
                                        <i v-if="order.deadline_at" class="fa-solid fa-triangle-exclamation ms-2" style="color: #ce0000; font-size: 1.25rem;"></i>
                                    </div>
                                </div>

                                <div class="d-flex">
                                    <div :style="{'width' : style.col_3_width + 'px'}"
                                         class="d-flex justify-content-center align-items-center">
                                        <OrderComponentsStatus :order="order" />
                                    </div>

                                    <div :style="{'width' : style.col_6_width + 'px'}"
                                         class="d-flex justify-content-center align-items-center">
                                        <span class="fw-bold">{{ order.orderline_quantity_sum }}</span>
                                    </div>

                                    <div :style="{'width' : style.col_7_width + 'px'}"
                                         class="d-flex justify-content-center align-items-center">
                                        <OrderFilesStatus :order="order" />
                                    </div>

                                    <div :style="{'width' : style.col_5_width + 'px'}"
                                         class="d-flex justify-content-end align-items-center pe-3">
                                        <OrderStatus class="flex-grow-1"
                                                     :status="order.production_status"
                                                     :percentage="order.production_percentage"
                                                     :text="order.production_status_text"
                                        />
                                    </div>
                                </div>

                            </div>
                        </router-link>
                    </div>
                </div>
            </div>


        </div>

    </div>

    <MenuModal :visible="modals.menu.visible" @hide="modals.menu.visible = false" />
</template>

<script>
import {storeToRefs} from 'pinia';
import {useOrderStore} from "@/stores/OrderStore";
import OrderStatus from "@/views/Order/Partials/OrderStatus";
import OrderComponentsStatus from "@/views/Order/Partials/OrderComponentsStatus";
import OrderFilesStatus from "@/views/Order/Partials/OrderFilesStatus";

import ORDER_STATUS from "@/constants/OrderStatus";
import MenuModal from "@/views/Modals/MenuModal.vue";

export default {
    components: {
        MenuModal,
        OrderStatus,
        OrderComponentsStatus,
        OrderFilesStatus,
    },

    setup() {
        const store = useOrderStore();

        const {orders} = storeToRefs(store)

        return {
            store,
            orders,
            interval: null,
            ORDER_STATUS,
        }
    },

    data() {
        return {
            style: {
                col_1_width: 220,
                col_2_width: 140,
                col_3_width: 180,
                col_4_width: 130,
                col_5_width: 225,
                col_6_width: 120,
                col_7_width: 90,
            },

            modals: {
                menu: {
                    visible: false,
                }
            },

            debtor: 'pyramid',
        }
    },

    computed: {
        ordersFiltered() {
            return this.orders.filter(order => {
                if (this.debtor === 'pyramid') {
                    return order.debtor_external_id === '11077';
                } else {
                    return order.debtor_external_id !== '11077';
                }
            })
        }
    },

    created() {
        this.store.fetchOrders();
        this.interval = setInterval(() => this.store.fetchOrders(), 5000);
    },

    beforeUnmount() {
        clearInterval(this.interval);
    },
}
</script>