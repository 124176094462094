import { defineStore } from 'pinia'
import OrderService from '@/services/OrderService'

export const useOrderStore = defineStore('orders', {
    state: () => ({
        orders: [],
        order: null,
        orderId: null,
        fetchingOrders: false,
        fetchingOrder: false,
    }),

    getters: {
        getOrders(state) {
            return state.orders;
        },
        getOrder(state) {
            return state.order;
        },
        isFetchingOrders(state) {
            return state.fetchingOrders;
        },
        isFetchingOrder(state) {
            return state.fetchingOrder;
        },
    },

    actions: {
        async fetchOrders() {
            if (this.fetchingOrders) {
                console.log('[OrderStore] - fetchOrders() - already fetching');
                return;
            }

            console.log('[OrderStore] - fetchOrders() - fetching');
            this.fetchingOrders = true;

            OrderService.all().then((response) => {
                console.log('[OrderStore] - fetchOrders() - received orders');
                this.orders = response.data;
                this.fetchingOrders = false;
            }).catch((error) => {
                console.error(error);
                this.orders = [];
                this.fetchingOrders = false;
            });
        },

        async fetchOrder() {
            if (this.fetchingOrder) {
                console.log('[OrderStore] - fetchOrder() - already fetching');
                return;
            }

            if (this.orderId === null) {
                console.log('[OrderStore] - fetchOrder() - cannot fetch order because order id is null');
                return;
            }

            console.log('[OrderStore] - fetchOrder() - fetching');
            this.fetchingOrder = true;

            OrderService.get(this.orderId).then((response) => {
                console.log('[OrderStore] - fetchOrder() - received orders');
                this.order = response.data;
                this.fetchingOrder = false;
            }).catch((error) => {
                console.error(error);
                this.order = null;
                this.fetchingOrder = false;
            });
        },

        setOrderId(id) {
            if (this.orderId === id) {
                return;
            }

            this.orderId = id;
            this.order = null;
        }
    }
})