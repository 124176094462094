<template>
    <div class="order-components-status d-flex justify-content-center align-items-center" v-if="order">
        <div v-if="order.production_print_pallet_labels_count > 0" class="d-flex align-items-center me-3" :class="getStatusClass('production_print_pallet_labels_completed')">
            <i class="fa-solid fa-box inline-block fs-1375"></i>
        </div>
        <div v-if="order.production_print_packing_lists_count > 0" class="d-flex align-items-center" :class="getStatusClass('production_print_packing_lists_completed')">
            <i class="fa-solid fa-rectangle-list inline-block fs-1375"></i>
        </div>
    </div>
</template>

<script>
import ORDER_STATUS from "@/constants/OrderStatus";

export default {
    props: {
        order: {
            required: true,
            type: Object,
        }
    },

    data() {
      return {
          ORDER_STATUS,
      }
    },

    methods: {
        getStatusClass(attribute) {
            let s = null;

            if (this.order.production_status === ORDER_STATUS.DRAFT) {
                s = 'draft'
            }

            if (this.order[attribute]) {
                s = 'processed'
            } else {
                s = 'processing'
            }

            if (!s) {
                return null;
            }

            return 'order-components-status--' + s;
        }
    },
}
</script>