<template>
    <div class="app-modal-overlay" v-if="visible">
        <div class="app-modal app-modal--large">
            <div class="app-modal-header d-flex justify-content-between">
                <span><span class="text-capitalize">{{ category.name }}</span> - Reprint</span>
                <span @click="hide()" role="button"><i class="fa-solid fa-xmark pt-1 pe-1 ps-3 pb-3"></i></span>
            </div>

            <div class="app-modal-body">
                <div v-if="componentsFiltered.length > 0">
                    <div v-for="component in componentsFiltered"
                         :key="component.external_id"
                         class="d-flex justify-content-between align-items-center p-2 mb-2"
                         :class="{'bg-warning bg-opacity-50': component.hasLabel, 'bg-light': !component.hasLabel}">
                        <div class="d-flex flex-column">
                            <div>
                                <strong class="d-inline-block fs-125" style="width: 120px">{{ component.parent_external_id }}</strong>
                                <strong class="d-inline-block fs-125" style="width: 120px">{{ component.external_id }}</strong>
                            </div>

                            <div>
                                {{ component.parent_name }}
                            </div>
                        </div>

                        <div class="d-flex align-items-center">
                            <div class="d-flex me-4" v-if="processingActionComponentIds[component.id] === true"><span class="loader"></span></div>
                            <div style="width: 100px;">
                                <input type="number" class="form-control fs-15" v-model="componentsQuantityValues[component.id]" :disabled="processing">
                            </div>
                        </div>
                    </div>

                    <div class="d-flex flex-column mt-4">
                        <div class="d-flex align-items-center p-2 bg-secondary bg-opacity-10">
                            <strong class="d-inline-block fs-125 me-3">Password</strong>
                            <input type="password" class="form-control fs-15" v-model="password" style="width: 200px;" readonly="readonly" :disabled="processing">
                            <div class="fs-2 ms-3 me-2">
                                <span v-if="passwordCorrect" class="text-success"><i class="fa-regular fa-circle-check"></i></span>
                                <span v-if="!passwordCorrect" class="text-danger"><i class="fa-regular fa-circle-xmark"></i></span>
                            </div>
                        </div>

                        <div class="keypad d-flex flex-column p-3 bg-secondary bg-opacity-10">
                            <div>
                                <span role="button" @click="keypadEnter('1')">1</span>
                                <span role="button" @click="keypadEnter('2')">2</span>
                                <span role="button" @click="keypadEnter('3')">3</span>
                            </div>
                            <div>
                                <span role="button" @click="keypadEnter('4')">4</span>
                                <span role="button" @click="keypadEnter('5')">5</span>
                                <span role="button" @click="keypadEnter('6')">6</span>
                            </div>
                            <div>
                                <span role="button" @click="keypadEnter('7')">7</span>
                                <span role="button" @click="keypadEnter('8')">8</span>
                                <span role="button" @click="keypadEnter('9')">9</span>
                            </div>
                            <div>
                                <span role="button" @click="keypadEnter('clear')"><i class="fa-solid fa-circle-xmark"></i></span>
                                <span role="button" @click="keypadEnter('0')">0</span>
                                <span role="button" @click="keypadEnter('backspace')"><i class="fa-solid fa-delete-left"></i></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <span class="fs-15">No items available for reprint</span>
                </div>
            </div>

            <div class="app-modal-footer d-flex align-items-center justify-content-start">
                <button class="btn btn-primary px-4 py-2" style="font-size: 1.5rem;" @click="process()" :disabled="!passwordCorrect || processing || componentsFiltered.length === 0">
                    Print
                </button>
                <div class="d-flex ms-4" v-if="processing"><span class="loader"></span></div>
                <div v-if="success" class="d-flex align-items-center text-success ms-4">
                    <span class="fs-2"><i class="fa-regular fa-circle-check"></i></span>
                    <span class="fs-15 d-inline-block ms-3">Completed</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ORDER_STATUS from "@/constants/OrderStatus";
import OrderLineComponentService from "@/services/OrderLineComponentService";
import {useOrderStore} from "@/stores/OrderStore";

export default {
    props: {
        category: {
            required: true,
            type: Object,
        }
    },

    setup() {
        const store = useOrderStore();

        return {
            store,
            ORDER_STATUS,
        }
    },

    data() {
        return {
            visible: false,
            success: false,

            password: '',

            processing: false,
            processingActionComponentIds: {},
            componentsQuantityValues: {},
        }
    },

    computed: {
        componentsFiltered() {
          return this.category.items.filter(function (item) {
              return ((!item.production_from_stock && item.production_status === ORDER_STATUS.PROCESSED) || item.hasLabel);
          })
        },
        passwordCorrect() {
            return this.password == '1706';
        },
    },

    methods: {
        async process() {
            if (this.processing || !this.passwordCorrect) {
                return;
            }

            this.processing = true;
            this.success = false;

            const decalExternalOrderId  = this.store.order.order.external_reference_1 + '_reprint_' + Date.now();
            const processedComponentIds = [];
            for (const component of this.category.items) {
                const quantity = this.componentsQuantityValues[component.id] || 0;

                if (quantity < 0) {
                    alert('Quantity cannot be lower than 0');
                    continue;
                }

                if (quantity > 5000) {
                    alert('Quantity cannot be larger than 5000');
                    continue;
                }

                if (quantity == 0) {
                    console.log('Component ' + component.id + ' quantity: ' + quantity + ' => SKIPPING');
                    continue;
                }
                console.log('Component ' + component.id + ' quantity: ' + quantity + ' => PROCESSING');

                processedComponentIds.push(component.id);
                this.processingActionComponentIds[component.id] = true;

                try {
                    await OrderLineComponentService.action(component.id, {
                        quantity: quantity,
                        from_stock: component.production_from_stock,
                        update_from_stock: false,
                        force: true,
                        decal_order_external_id: decalExternalOrderId,
                    });

                    this.processingActionComponentIds[component.id] = false;
                    console.log('Component ' + component.id + ' quantity: ' + quantity + ' => PROCESSED');
                } catch (error) {
                    console.error(error)
                    alert('Error, please try again.');
                    window.location.reload();
                    return;
                }
            }

            if (this.category.name === 'decal') {
                try {
                    console.log('PRINT DECALS => PROCESSING');
                    await OrderLineComponentService.printDecals(processedComponentIds, decalExternalOrderId)
                    console.log('PRINT DECALS => PROCESSED');
                } catch (error) {
                    console.error(error)
                    alert('Error, please try again.');
                    window.location.reload();
                    return;
                }
            }

            this.processing = false;
            this.success = true;
        },
        keypadEnter(key) {
            switch(key) {
                case 'backspace':
                    this.password = this.password.slice(0, -1);
                    break;
                case 'clear':
                    this.password = '';
                    break;
                default:
                    this.password += key;
                    break;
            }
        },
        hide() {
            this.visible = false;
        },
        show() {
            for (let item of this.category.items) {
                this.componentsQuantityValues[item.id] = 0;
            }

            this.visible = true;
        }
    }
}
</script>