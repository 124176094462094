import { createApp } from 'vue'
import App from './App.vue'

const app = createApp(App)

import events from './lib/events'
import router from './lib/router'
import store from './lib/store'

// Event Bus
app.config.globalProperties.$events = events;

import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
library.add(fas, far, fab)
dom.watch();

app.use(router)
app.use(store)
app.mount('#app');