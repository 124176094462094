import axios from '@/lib/axios'

class OrderLineComponentService {
    action(id, fields) {
        return axios.post('/order-line-component/' + id + '/action', fields);
    }

    printDecals(ids, decalExternalOrderId = null) {
        return axios.post('/order-line-component/print-decals', {
            'order_line_component_ids': ids,
            'decal_order_external_id': decalExternalOrderId,
        })
    }
}

export default new OrderLineComponentService();