<template>
    <div class="row view-order-categories" v-if="store.order">

        <div class="position-fixed bg-light start-0 top-0 end-0 d-flex justify-content-between align-items-center py-3 mb-3" style="z-index: 10;">
            <div class="d-flex flex-column">
                <span class="fs-15">Order: <strong>{{ store.order.order.external_reference_1 }}</strong></span>
                <span class="fs-1125">Mug count: <strong>{{ store.order.order.orderline_quantity_sum }}</strong></span>
            </div>
            <div>
                <router-link :to="{name: 'order.index'}" class="d-flex align-items-center btn btn-primary py-2 px-4 fs-125">
                    <strong>Back</strong>
                </router-link>
            </div>
        </div>

        <div class="col-12">
            <router-link
                v-for="(category, key) in categoriesFiltered"
                :key="key"
                :to="{ name: 'order.category', params: { id: store.orderId, category: key }}"
                class="text-decoration-none text-body">
                <div class="d-flex flex-row justify-content-between align-items-center p-2 mb-2 border border-3"
                     :class="{'mt-5': key === 'barcode' || key === 'pallet'}">
                    <strong class="text-capitalize fs-4">{{ key }}</strong>
                    <span class="d-flex justify-content-end" v-if="category.production_status !== ORDER_STATUS.DRAFT"
                          style="width: 200px;">
            <OrderStatus class="flex-grow-1" :status="category.production_status"
                         :percentage="category.production_percentage"></OrderStatus>
          </span>
                </div>
            </router-link>
        </div>
    </div>
    <div class="row flex-grow-1 mt-4" v-if="store.order">
        <div class="col-md-6 d-flex flex-column justify-content-end">
            <div v-if="store.order.order.production_status === ORDER_STATUS.PROCESSED" class="d-flex justify-content-start align-items-center">
                <button class="btn btn-primary px-4 py-2 fs-15" @click="requestShipment">
                    Request shipment
                </button>
                <div class="d-flex ms-4"><span class="loader" v-if="isRequestingShipment"></span></div>
            </div>
            <div v-if="store.order.order.production_status === ORDER_STATUS.SHIPMENT_CONFIRMED" class="d-flex flex-column">
                <div v-if="store.order.order.production_shipment_date" class="mb-3">
                    <span class="fs-15">Shipment date:</span><strong class="fs-15 ms-2">{{ store.order.order.production_shipment_date }}</strong>
                </div>
                <div class="d-flex justify-content-start align-items-end">
                    <div class="d-flex flex-column align-items-start me-4">
                        <button v-if="store.order.order.production_print_pallet_labels_count > 0"
                                class="btn text-white px-4 py-2 mb-3 fs-15"
                                :class="{'btn-success': store.order.order.production_print_pallet_labels_completed, 'btn-primary': !store.order.order.production_print_pallet_labels_completed}"
                                @click="$refs.modalPrintPalletLabels.show()">
                            <i class="fa-regular fa-circle-check me-3" v-if="store.order.order.production_print_pallet_labels_completed"></i>Print pallet labels ({{ store.order.order.production_print_pallet_labels_count }})
                        </button>
                        <button v-if="store.order.order.production_print_packing_lists_count > 0"
                                class="btn text-white px-4 py-2 fs-15"
                                :class="{'btn-success': store.order.order.production_print_packing_lists_completed, 'btn-primary': !store.order.order.production_print_packing_lists_completed}"
                                @click="$refs.modalPrintPackingLists.show()">
                            <i class="fa-regular fa-circle-check me-3"  v-if="store.order.order.production_print_packing_lists_completed"></i>Print packing lists ({{ store.order.order.production_print_packing_lists_count }})
                        </button>
                    </div>
                    <button class="btn btn-primary px-4 py-2 fs-15" @click="$refs.modalShip.show()">
                        Shipped
                    </button>
                </div>
            </div>
        </div>
        <div class="col-md-6 d-flex flex-column justify-content-end">
            <div v-if="store.order.order.remark !== null && store.order.order.remark !== ''" class="d-flex flex-column align-items-end">
                <div v-if="showRemark" class="mb-3 bg-warning bg-opacity-50 p-3 text-black fs-125">
                    {{ store.order.order.remark }}
                </div>
                <button class="btn btn-warning px-4 py-2 fs-15" @click="showRemark = !showRemark">
                    <i class="fa-solid fa-triangle-exclamation d-inline-block me-2"></i> Remark!
                </button>
            </div>
        </div>

        <ModalShip ref="modalShip" />
        <ModalPrintPalletLabels ref="modalPrintPalletLabels" />
        <ModalPrintPackingLists ref="modalPrintPackingLists" />
    </div>
</template>

<script>
import {useOrderStore} from "@/stores/OrderStore";
import ORDER_STATUS from "@/constants/OrderStatus";
import OrderStatus from "@/views/Order/Partials/OrderStatus";
import ModalShip from "@/components/ModelShip";
import ModalPrintPalletLabels from "@/components/ModalPrintPalletLabels";
import ModalPrintPackingLists from "@/components/ModalPrintPackingLists";

import OrderService from "@/services/OrderService";

export default {
    components: {
        OrderStatus,
        ModalShip,
        ModalPrintPalletLabels,
        ModalPrintPackingLists,
    },

    setup() {
        const store = useOrderStore();

        return {
            store,
        }
    },

    data() {
        return {
            ORDER_STATUS,

            showRemark: false,
            isRequestingShipment: false,
        }
    },

    computed: {
        categoriesFiltered() {
            return Object.keys(this.store.order.categories).filter((key) => {
                return this.store.order.categories[key].production_status !== null;
            }).reduce((cur, key) => {
                return Object.assign(cur, {[key]: this.store.order.categories[key]})
            }, {});
        },
    },

    methods: {
        requestShipment() {
            if (this.isRequestingShipment) {
                return true;
            }

            this.isRequestingShipment = true;
            OrderService.requestTransport(this.store.orderId).then(() => {
                setTimeout(() => {
                    this.isRequestingShipment = false;
                }, 10000);
            }).catch((error) => {
                alert(error.response.message);
                this.isRequestingShipment = false;
            })
        },
    },

    created() {
        if (this.store.order == null || this.store.orderId !== this.$route.params.id) {
            this.store.setOrderId(this.$route.params.id);
            this.store.fetchOrder();
        }

        this.interval = setInterval(() => this.store.fetchOrder(), 5000)
    },

    beforeUnmount() {
        clearInterval(this.interval);
    },
}
</script>