import axios from '@/lib/axios'

export default class PrintService {
    get(uuid) {
        return axios.get('/print/tray/' + uuid);
    }

    printTrayBarcodes(uuid, data) {
        return axios.post('/print/tray/' + uuid + '/labels', data);
    }
}