import axios from '@/lib/axios'

class OrderLineService {
    all() {
        return axios.get('/order');
    }

    get(id) {
        return axios.get('/order/' + id);
    }

    requestTransport(id) {
        return axios.get('/order/' + id  + '/request-transport');
    }

    setStatusShipped(id, fields) {
        return axios.post('/order/' + id + '/ship', fields);
    }

    printPalletLabel(id) {
        return axios.get('/order/' + id + '/print-pallet-label');
    }

    printPackingList(id) {
        return axios.get('/order/' + id + '/print-packing-list');
    }
}

export default new OrderLineService();