<template>

    <div class="row view-order-category" v-if="store.order && category">

        <div class="position-fixed bg-light start-0 top-0 end-0 d-flex justify-content-between align-items-center py-3 mb-3" style="z-index: 10;">
            <div class="d-flex flex-column">
                <span class="fs-15">Order: <strong>{{ store.order.order.external_reference_1 }} - {{ $route.params.category }}</strong></span>
                <span class="fs-1125">Mug count: <strong>{{ store.order.order.orderline_quantity_sum }}</strong></span>
            </div>
            <div>
                <router-link :to="{ name: 'order.categories', params: { id: store.orderId }}"
                             class="d-flex align-items-center btn btn-primary py-2 px-4 fs-125"><strong>Back</strong>
                </router-link>
            </div>
        </div>

        <div class="col-12">

            <div v-for="item in category.items"
                 :key="item.id"
                 class="d-flex flex-row justify-content-between align-items-center py-2 ps-3 pe-2 mb-2"
                 :class="{'bg-warning bg-opacity-50': item.hasLabel, 'bg-light': !item.hasLabel}"
            >

                <template
                    v-if="$route.params.category in {'decal': null, 'barcode': null, 'label': null, 'translation': null, 'generic_box': null, 'branded_box': null}">
                    <div class="d-flex flex-column">
                        <div>
                            <strong class="d-inline-block fs-125" style="width: 120px">{{ item.parent_external_id }}</strong>
                            <strong class="d-inline-block fs-125" style="width: 120px">{{ item.external_id }}</strong>
                            <label class="d-inline-block ms-5 fs-125" style="width: 120px"
                                   v-if="item.production_status === ORDER_STATUS.PROCESSING"><input type="checkbox"
                                                                                                    v-model="componentsFromStockValues[item.id]"
                                                                                                    class="d-inline-block me-2">Stock</label>
                            <label class="d-inline-block ms-3 fs-125" style="width: 120px"
                                   v-if="item.production_status === ORDER_STATUS.PROCESSING"><input type="checkbox"
                                                                                                    v-model="componentsFromPrintValues[item.id]"
                                                                                                    class="d-inline-block me-2">Print</label>

                            <span v-if="item.production_status === ORDER_STATUS.PROCESSED" class="d-inline-block ms-3 fs-125" style="width: 160px">
                               <span v-if="item.production_from_stock" class="text-secondary">
                                    <i class="fa-solid fa-cubes-stacked"></i>
                                    <strong class="d-inline-block ms-2">From stock</strong>
                                </span>
                               <span v-if="!item.production_from_stock" class="text-success">
                                    <i class="fa-solid fa-print"></i>
                                    <strong class="d-inline-block ms-2">Printed</strong>
                                </span>
                            </span>
                        </div>
                        <div>
                                     <span class="d-flex align-items-center">
                            {{ item.parent_name }}
                        </span>
                        </div>
                    </div>
                            <span class="d-flex align-items-center">
                      <span class="d-inline-block me-4 fs-125">Quantity: <strong>{{ item.quantity }}</strong></span>

                      <span class="d-flex align-items-center justify-content-end">
                        <span class="d-inline-block" v-if="item.production_status !== ORDER_STATUS.DRAFT"><OrderStatus
                            :status="item.production_status"></OrderStatus></span>

                        <span class="d-flex align-items-center ms-3" v-if="processingActionComponentIds[item.id] === true">
                          <span class="loader"></span>
                        </span>
                      </span>
                    </span>
                </template>


                <template v-if="$route.params.category === 'box'">
          <span class="d-flex flex-column flex-md-row align-items-md-center">
              <strong style="width: 100px" class="d-inline-block">{{ item.external_id }}</strong>
              <span style="width: 100px" class="d-inline-block">Labels: <strong>{{ item.items.length }}</strong></span>
              <strong style="max-width: 300px" class="d-inline-block text-nowrap text-truncate">{{ item.items[0].component.name }}</strong>
          </span>
                    <span class="d-flex justify-content-end align-items-center flex-grow-1">
            <span class="d-inline-block flex-grow-1 ms-4 me-4" v-if="item.production_status !== ORDER_STATUS.DRAFT" style="max-width: 200px;">
              <OrderStatus :status="item.production_status" :percentage="item.production_percentage"/>
            </span>
            <span class="d-flex align-items-center">
              <span class="loader" v-if="processingActionBoxExternalId === item.external_id"></span>
              <button class="btn btn-primary d-inline-block ms-3 px-4 py-2" @click="actionBox(item, item.external_id)"
                      v-if="item.production_status === ORDER_STATUS.PROCESSING"
                      :disabled="this.processingActionBoxExternalId !== null"><strong>Print</strong></button>
            </span>
          </span>
                </template>


                <template v-if="$route.params.category === 'pallet'">
          <span>
            <span class="d-inline-block" style="width: 100px">Pallet: <strong>{{ item.index }}</strong></span>
            <span class="d-inline-block" style="width: 100px">Collo:  <strong>{{ item.box_count }}</strong></span>
          </span>
                    <span class="d-flex align-items-center">
            <span class="d-inline-block me-4"><OrderStatus :status="item.production_status"
                                                           v-if="item.production_status !== ORDER_STATUS.DRAFT"></OrderStatus></span>
            <span class="d-flex align-items-center">
              <span class="loader" v-if="processingActionPalletId === item.id"></span>
              <button class="btn btn-primary d-inline-block ms-3 px-4 py-2" @click="actionPallet(item)"
                      v-if="item.production_status !== ORDER_STATUS.DRAFT"
                      :disabled="this.processingActionPalletId !== null"><strong>Print</strong></button>
            </span>
          </span>
                </template>

            </div>

            <template v-if="$route.params.category === 'decal'">
                <div class="d-flex bg-light py-2" style="padding-left: 304px;">
                    <label class="d-inline-block fs-125" style="width: 120px"><input type="checkbox" class="d-inline-block me-2" v-model="stockAll">Stock all</label>
                    <label class="d-inline-block fs-125 ms-3" style="width: 120px"><input type="checkbox" class="d-inline-block me-2" v-model="printAll">Print all</label>
                </div>
            </template>

            <div class="d-flex justify-content-end mt-3" v-if="$route.params.category !== 'pallet' && $route.params.category !== 'box'">
                <div class="d-flex align-items-center">
                    <span class="loader" v-if="processingActionComponents"></span>

                    <template v-if="$route.params.category === 'decal'">
                        <div class="d-flex align-items-center text-primary ms-4" v-if="processingPrintDecals">
                            <span class="loader"></span>
                            <span class="fs-15 d-inline-block ms-3">Adding decals to printer queue</span>
                        </div>
                        <div v-if="completedPrintDecals" class="d-flex align-items-center text-success ms-4">
                            <span class="fs-2"><i class="fa-regular fa-circle-check"></i></span>
                            <span class="fs-15 d-inline-block ms-3">Decals queued</span>
                        </div>
                    </template>

                    <button class="btn btn-primary d-inline-block ms-3 px-4 py-2 fs-15" @click="actionComponents()"
                            v-if="category.production_status === ORDER_STATUS.PROCESSING"
                            :disabled="processingActionComponents"><strong>Print</strong></button>
                </div>
                <div class="d-flex justify-content-center">
                    <button class="btn btn-primary fs-15 ms-3 px-4 py-2" @click="$refs.reprintComponentsModal.show()">
                        Reprint
                    </button>
                </div>
            </div>
        </div>

        <ReprintComponentsModal ref="reprintComponentsModal" :category="category" />
    </div>
</template>

<script>
import {useOrderStore} from "@/stores/OrderStore";

import OrderPalletService from "@/services/OrderPalletService";
import OrderBoxService from "@/services/OrderBoxService";
import OrderLineComponentService from "@/services/OrderLineComponentService";

import OrderStatus from "@/views/Order/Partials/OrderStatus";
import ORDER_STATUS from "@/constants/OrderStatus";

import ReprintComponentsModal from "@/views/Order/Modals/ReprintComponentsModal";

export default {
    components: {
        OrderStatus,
        ReprintComponentsModal,
    },

    data() {
        return {
            ORDER_STATUS,

            components: {},

            componentsFromStockValues: {},
            componentsFromPrintValues: {},

            processingActionComponents: false,
            processingActionComponentIds: {},
            processingActionPalletId: null,
            processingActionBoxExternalId: null,

            processingPrintDecals: false,
            completedPrintDecals: false,

            stockAll: false,
            printAll: false,
        }
    },

    setup() {
        const store = useOrderStore();

        return {
            store,
        }
    },

    computed: {
        category() {
            return this.store.order.categories[this.$route.params.category];
        }
    },

    methods: {
        async actionComponents() {
            if (this.processingActionComponents) {
                return;
            }

            this.processingPrintDecals = false;
            this.completedPrintDecals = false;
            this.processingActionComponents = true;

            const printComponentsIds = [];
            const processedComponentIds = [];
            for (const component of this.category.items) {
                const stock = this.componentsFromStockValues[component.id] || false;
                const print = this.componentsFromPrintValues[component.id] || false;

                if (component.production_status === ORDER_STATUS.COMPLETED) {
                    console.log('Skipping order-line-component(' + component.id + '), SKIPPING -  status === COMPLETED')
                    continue;
                }

                if (component.production_status === ORDER_STATUS.PROCESSED) {
                    console.log('Skipping order-line-component(' + component.id + '), SKIPPING -  status === PROCESSED')
                    continue;
                }

                if (!print && !stock) {
                    console.log('Skipping order-line-component(' + component.id + '), SKIPPING - print => false && stock => false')
                    continue;
                }

                if (print) {
                    printComponentsIds.push(component.id);
                }

                processedComponentIds.push(component.id);
                this.processingActionComponentIds[component.id] = true;

                try {
                    console.log('order-line-component(' + component.id + ') => PROCESSING')
                    const response = await OrderLineComponentService.action(component.id, {
                        from_stock: stock,
                    });

                    // If HTTP_OK
                    if (response.status === 200) {
                        component.production_status = ORDER_STATUS.PROCESSED;
                    }

                    this.processingActionComponentIds[component.id] = false;
                    console.log('order-line-component(' + component.id + ') => PROCESSED')
                } catch (error) {
                    console.error(error)
                    alert('Error, please try again.');
                    window.location.reload();
                    return;
                }
            }

            // No components to print
            if (printComponentsIds.length === 0) {
                this.processingActionComponents = false;

                // Fetch order data
                this.store.fetchOrder();
                return;
            }

            // Component actions have been completed
            this.processingActionComponents = false;

            // Send decals to print application
            if (this.category.name === 'decal') {
                this.processingPrintDecals = true;
                try {
                    console.log('PRINT DECALS => PROCESSING');
                    await OrderLineComponentService.printDecals(printComponentsIds)
                    console.log('PRINT DECALS => PROCESSED');
                } catch (error) {
                    console.error(error)
                    alert('Error, please try again.');
                    window.location.reload();
                    return;
                }
                this.processingPrintDecals = false;
                this.completedPrintDecals = true;
            }

            // Fetch order data
            this.store.fetchOrder();
        },

        actionPallet(pallet) {
            if (this.processingActionPalletId !== null) {
                return;
            }

            this.processingActionPalletId = pallet.id;
            OrderPalletService.action(pallet.id).finally(async () => {
                // Fetch order data
                await this.store.fetchOrder();

                setTimeout(() => {
                    this.processingActionPalletId = null;
                }, 500);
            })
        },

        async actionBox(groupedBoxes, externalId) {
            if (this.processingActionBoxExternalId !== null) {
                return;
            }

            this.processingActionBoxExternalId = externalId;

            const ids = groupedBoxes.items
                .filter((box) => box.production_status === ORDER_STATUS.PROCESSING)
                .map((box) => box.id)
                .sort((a, b) => a - b);

            for (let id of ids) {
                try {
                    await OrderBoxService.action(id);
                    console.log('Request for id: ' + id + ' => finished');
                } catch (error) {
                    console.error(error);
                }
            }

            this.processingActionBoxExternalId = null;

            // Fetch order data
            this.store.fetchOrder();
        },

        setData() {
            // Set components
            for (let item of this.category.items) {
                this.componentsFromStockValues[item.id] = item.production_from_stock;
                this.componentsFromPrintValues[item.id] = false;
            }
        }
    },

    created() {
        if (this.store.order == null || this.store.orderId !== this.$route.params.id) {
            this.store.setOrderId(this.$route.params.id)
            this.store.fetchOrder()
        } else {
            this.$nextTick(() => {
                this.setData();
            })
        }

        this.interval = setInterval(() => this.store.fetchOrder(), 15000)
    },

    beforeUnmount() {
        clearInterval(this.interval);
    },

    watch: {
        'store.order.order.id': function (value) {
            if (value === null) {
                return;
            }

            this.setData();
        },

        stockAll(value) {
            for (const item of this.category.items) {
                this.componentsFromStockValues[item.id] = value;
            }
        },

        printAll(value) {
            for (const item of this.category.items) {
                this.componentsFromPrintValues[item.id] = value;
            }
        },
    },
}
</script>

<style>
.loader {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    margin: 0 auto;
    border-top: 3px solid #0d6efd;
    border-right: 3px solid rgba(0, 0, 0, .1);
    border-bottom: 3px solid rgba(0, 0, 0, .1);
    border-left: 3px solid rgba(0, 0, 0, .1);
    border-radius: 20px;
    animation: spin 1s infinite linear;
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}
</style>