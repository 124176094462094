<template>
    <div class="app-modal-overlay" v-if="visible">
        <div class="app-modal">
            <div class="app-modal-header d-flex justify-content-between">
                <span>Order - Print packing lists</span>
                <span @click="hide()" role="button"><i class="fa-solid fa-xmark pt-1 pe-1 ps-3 pb-3"></i></span>
            </div>

            <div class="app-modal-body">
                <span class="d-block mb-1 fs-1125">Are you sure you want to print the packing lists?</span>
            </div>

            <div class="app-modal-footer d-flex align-items-center justify-content-start">
                <button class="btn btn-primary px-4 py-2" style="font-size: 1.5rem;" @click="process()">
                    Print
                </button>
                <div class="d-flex ms-4" v-if="processing"><span class="loader"></span></div>
            </div>
        </div>
    </div>
</template>

<script>
import OrderService from "@/services/OrderService";
import {useOrderStore} from "@/stores/OrderStore";

export default {

    setup() {
        const store = useOrderStore();

        return {
            store,
        }
    },

    data() {
        return {
            visible: false,
            processing: false,
        }
    },

    methods: {
        process() {
            if (this.processing) {
                return;
            }

            this.processing = true;

            OrderService.printPackingList(this.store.orderId).then(() => {
                setTimeout(() => {
                    this.processing = false;
                    this.hide();
                }, 5000);
            }).catch((error) => {
                alert(error.response.message);
                this.processing = false;
                this.hide();
            })
        },
        hide() {
            this.visible = false;
        },
        show() {
            this.visible = true;
        }
    }
}
</script>