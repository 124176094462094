<template>
  <div class="row">
    <div class="col-12 mt-2 mb-4">
      <div class="d-flex justify-content-between">
        <h2 class="text-primary text-start m-0">Settings</h2>
      </div>

      <div class="form-group mt-4">
        <label class="d-block text-start">API Token</label>
        <input class="form-control" v-model="token">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      token: window.localStorage.getItem('api_token'),
    }
  },

  watch: {
    token(value) {
      window.localStorage.setItem('api_token', value);
    },
  }
}
</script>
