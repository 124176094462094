<template>
    <div class="app-modal-overlay" v-if="visible">
        <div class="app-modal app-modal--large ">
            <div class="app-modal-body px-3 pt-3 pb-0 mb-3">
                <div class="d-flex">
                    <router-link :to="{ name: 'scan.index'}" class="text-decoration-none text-body">
                        <div class="d-flex flex-column align-items-center bg-light rounded-3 p-2">
                            <img src="/images/qr.png" style="width: 12rem; height: 12rem" />
                            <strong style="font-size: 1.5rem;" class="mb-1">QR Scanner</strong>
                        </div>
                    </router-link>
                </div>
            </div>
            <div class="app-modal-footer d-flex align-items-center justify-content-end">
                <button class="btn btn-light px-4 py-2" style="font-size: 1.5rem;" @click="hide">Close</button>
            </div>
        </div>
    </div>
</template>

<script>
import 'vue-json-pretty/lib/styles.css';

export default {
    props: {
        visible: Boolean,
    },

    data() {
        return {}
    },

    methods: {
        hide() {
            this.$emit('hide')
        }
    },
}
</script>