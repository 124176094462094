<template>
    <div class="order-components-status text-nowrap text-end" v-if="order">
        <div v-if="order.production_decal_status" class="d-inline-block me-3" :class="getStatusClass(order.production_decal_status)">
            <i class="fa-solid fa-image inline-block fs-1375"></i>
        </div>
        <div v-if="order.production_barcode_status" class="d-inline-block me-3" :class="getStatusClass(order.production_barcode_status)">
            <i class="fa-solid fa-barcode inline-block fs-1375"></i>
        </div>
        <div v-if="order.production_label_status" class="d-inline-block me-3" :class="getStatusClass(order.production_label_status)">
            <i class="fa-solid fa-tag inline-block fs-1375"></i>
        </div>
    </div>
</template>

<script>
import ORDER_STATUS from "@/constants/OrderStatus";

export default {
    props: {
        order: {
            required: true,
            type: Object,
        }
    },

    data() {
      return {
          ORDER_STATUS,
      }
    },

    methods: {
        getStatusClass(status) {
            let s = null;
            switch (status) {
                case ORDER_STATUS.DRAFT:
                    s = 'draft'
                    break
                case ORDER_STATUS.PROCESSING:
                    s = 'processing'
                    break
                case ORDER_STATUS.PROCESSED:
                    s = 'processed'
                    break
            }

            if (!s) {
                return null;
            }

            return 'order-components-status--' + s;
        }
    },
}
</script>