<template>
    <div class="order-status text-nowrap text-end" :class="classes">

        <template v-if="!showPercentage">
            <strong class="order-status-badge">
                <span class="d-block order-status-badge-status-text">{{ statusText }}</span>
                <span class="d-block order-status-badge-text" v-if="text" v-html="text"></span>
            </strong>
        </template>

        <template v-if="showPercentage">
            <div class="order-status-progress-text d-flex justify-content-between align-content-center">
                <span class="d-block">{{ statusText }}</span>
                <span>{{ percentage }}%</span>
            </div>
            <div class="order-status-progress">
                <div class="order-status-progress-background" :style="{
            'width': '100%',
          }"></div>
                <div class="order-status-progress-filled" :style="{
            'width': percentage + '%',
          }"></div>
            </div>
        </template>

    </div>
</template>

<script>
import ORDER_STATUS from "@/constants/OrderStatus";

export default {
    props: {
        status: {
            required: true,
            type: Number,
            nullable: true,
        },
        percentage: {
            required: false,
            type: Number,
            nullable: true,
        },
        text: {
            required: false,
            type: String,
            nullable: true,
        },
    },

    data() {
        return {
            ORDER_STATUS,
        }
    },

    computed: {
        classes() {
            switch (this.status) {
                case ORDER_STATUS.DRAFT:
                    return 'order-status--draft';
                case ORDER_STATUS.PROCESSING:
                case ORDER_STATUS.SHIPMENT_REQUESTED:
                case ORDER_STATUS.SHIPMENT_CONFIRMED:
                case ORDER_STATUS.SHIPPED:
                    return 'order-status--processing';
                case ORDER_STATUS.PROCESSED:
                    return 'order-status--processed';
            }

            return null;
        },

        statusText() {
            switch (this.status) {
                case ORDER_STATUS.DRAFT:
                    return 'On hold';
                case ORDER_STATUS.QUEUED:
                    return 'Queued';
                case ORDER_STATUS.PROCESSING:
                    return 'Processing';
                case ORDER_STATUS.PROCESSED:
                    return 'Processed';
                case ORDER_STATUS.SHIPMENT_REQUESTED:
                    return 'Shipment requested';
                case ORDER_STATUS.SHIPMENT_CONFIRMED:
                    return 'Shipment confirmed';
                case ORDER_STATUS.SHIPPED:
                    return 'Shipped';
                case ORDER_STATUS.COMPLETED:
                    return 'Completed';
            }

            return null;
        },

        showPercentage() {
            return this.percentage != null && (this.status === ORDER_STATUS.PROCESSING || this.status === ORDER_STATUS.PROCESSED || this.status === ORDER_STATUS.COMPLETED)
        }
    },
}
</script>