<template>
    <div class="d-flex flex-row justify-content-between align-items-center py-3 bg-light ps-3 pe-4">
        <img src="/images/logo.png" style="max-height: 40px;" />
        <router-link :to="{name: 'order.index'}" class="d-flex align-items-center btn btn-primary py-2 px-4 fs-125">
            <strong>Back</strong>
        </router-link>
    </div>

    <section class="d-flex flex-column flex-grow-1 bg-white">
        <div class="d-flex scan">
            <video ref="video" autoplay="true" style="width: 100%; max-height: 82vh;" class="bg-white"></video>
        </div>

        <div v-if="codeScannedTwice" class="p-3 rounded-3 border border-3 border-danger bg-danger bg-opacity-10 m-3">
            <strong class="text-danger" style="font-size: 1.25rem;">QR code is the same as the previous scanned QR code</strong>
        </div>
    </section>

    <ScanResultModal :code="code" :visible="modal.visible" @hide="modal.visible = false" />
</template>

<script>
import QrScanner from 'qr-scanner';
import ScanResultModal from "@/views/Scan/Modals/ScanResultModal.vue";


export default {
    components: {ScanResultModal},

    data () {
        return {
            code: null,
            codeScannedTwice: true,

            modal: {
                visible: false,
            },

            scanner: null,
            interval: null,
        }
    },

    mounted() {
        // Create new QR scanner instance
        this.scanner = new QrScanner(this.$refs.video, result => this.onDecode(result), {
            preferredCamera: 'environment',
            highlightScanRegion: true,
            highlightCodeOutline: true,
        })
        this.scanner.start()

        // Remove animations from scan highlight region
        this.interval = setInterval(() => {
            const elements = document.getElementsByClassName('scan-region-highlight-svg');
            for (const element of elements) {
                if (element.getAnimations().length > 0) {
                    element.getAnimations()[0].cancel()
                }
            }
        }, 100)
    },

    beforeUnmount() {
        this.scanner.destroy()
        clearInterval(this.interval)
    },

    methods: {
        onDecode(result) {
            if (this.modal.visible) {
                return;
            }

            if (result?.data == null || result?.data === '') {
                return;
            }

            this.codeScannedTwice = false;
            if (this.code === result.data) {
                this.codeScannedTwice = true;
            }

            this.code = result.data;
            this.modal.visible = true;
            this.$refs.video.pause();
        },
    },

    watch: {
        'modal.visible': function () {
            if (!this.modal.visible) {
                this.$refs.video.play();
            }
        }
    },
}
</script>

<style scoped>
    :deep(.scan .scan-region-highlight-svg), :deep(.scan .code-outline-highlight) {
        stroke: rgba(26, 89, 153) !important;
        animation:initial !important;
    }
</style>