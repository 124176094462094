<template>
  <section class="d-flex flex-column flex-grow-1">
    <router-view/>
  </section>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style lang="scss">
body {
  background-color: white !important;
}

#app {
  font-family: "Nunito Sans", ui-sans-serif, system-ui;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@import "./assets/scss/app";
</style>
